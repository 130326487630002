<template>
  <div>
    <loader :loading="loading"></loader>

    <v-row>
      <v-col cols="12">
        <div class="mb-5">
          <v-btn @click="$router.back()">
            <v-icon left>mdi-arrow-left</v-icon>
            назад
          </v-btn>
        </div>

        <div class="d-flex align-center">
          <h1 class="mr-4">Заявка № {{item.requestNumber}}</h1>
          <v-chip :color="getStatusColor(item.status)" dark small>{{getStatusTitle(item.status)}}</v-chip>
        </div>
        <v-rating
            v-model="item.rating"
            length="5"
            background-color="orange lighten-3"
            color="orange"
            readonly
        ></v-rating>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <v-card class="mb-5">
          <v-card-text>
            <div>
              <h5>Фамилия</h5>
              <p>{{applicant.lastName}}</p>
            </div>

            <div>
              <h5>Имя</h5>
              <p>{{applicant.firstName}}</p>
            </div>

            <div>
              <h5>Отчество</h5>
              <p>{{applicant.patronymic}}</p>
            </div>

            <div>
              <h5>Телефон</h5>
              <p>{{applicant.numberPhone}}</p>
            </div>

            <div>
              <h5>Адрес</h5>
              <p>{{address}}</p>
            </div>

            <div>
              <h5>Описание</h5>
              <p>{{item.description}}</p>
            </div>
          </v-card-text>
        </v-card>

        <v-card>
          <v-card-text>
            <h5>История</h5>
            <div v-if="item.hasOwnProperty('histories') && item.histories.length > 0">
              <div
                v-for="(h, index) in item.histories"
                :key="index"
                class="mb-4"
              >
                <div class="d-flex align-center mb-1">
                  <p class="mr-2 mb-0">{{dateFormat(h.createdAt)}}</p>
                  <v-chip label x-small :color="getStatusColor(h.status)" dark>{{getStatusTitle(h.status)}}</v-chip>
                </div>
                <p class="text-body-2">{{h.ticketComment}}</p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-select
          v-model="item.categoryId"
          :disabled="item.status === 3"
          :items="categories"
          label="Категория"
          item-text="name"
          item-value="id"
          :error-messages="errors.categoryId"
          outlined
          clearable
        ></v-select>
        <v-select
          v-if="user.role === 'SuperAdmin' || user.role === 'OSIManager' || (user.role === 'Dispatcher' && user.osiId)"
          v-model="item.employeeId"
          :items="employees"
          :disabled="item.status >= 3"
          label="Исполнитель"
          item-text="name"
          item-value="id"
          outlined
          clearable
        ></v-select>
        <v-select
          v-if="user.role === 'SuperAdmin' || user.role === 'ManagementCompany' || (user.role === 'Dispatcher' && user.managementCompanyId)"
          v-model="item.mcEmployeeId"
          :items="mcEmployees"
          :disabled="item.status >= 3"
          label="Исполнитель УК"
          item-text="name"
          item-value="id"
          outlined
          clearable
        ></v-select>
<!--        <v-select
          v-if="user.role === 'SuperAdmin' || user.role === 'WarrantyDepartment'"
          v-model="item.wdEmployeeId"
          :items="wdEmployees"
          :disabled="item.status === 3"
          label="Исполнитель ГО"
          item-text="name"
          item-value="id"
          outlined
          clearable
        ></v-select>-->
        <v-dialog
          ref="dialog"
          v-model="menu"
          :return-value.sync="item.expectedExecutionDate"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="item.expectedExecutionDate"
              label="Дата исполнения"
              prepend-inner-icon="mdi-calendar"
              :error-messages="errors.expectedExecutionDate"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="item.expectedExecutionDate"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog.save(item.expectedExecutionDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

        <v-menu
          ref="menu"
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="time"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="time"
              label="Время"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              outlined
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="menu2"
            v-model="time"
            full-width
            format="24hr"
            @click:minute="$refs.menu.save(time)"
          ></v-time-picker>
        </v-menu>

        <v-textarea
          v-model="comment"
          :disabled="item.status === 3"
          label="Комментарий"
          outlined
          clearable
        ></v-textarea>

<!--        <v-file-input
          v-model="files"
          show-size
          outlined
          multiple
          chips
          counter
          :rules="rules"
          label="Прикрепить файлы"
          @change="fileAdded"
        >
          <template #selection="{ index, text }">
            <v-chip small label close color="primary" @click:close="remove(index)">{{ text }}</v-chip>
          </template>
        </v-file-input>

        <p v-if="!fileError" class="red&#45;&#45;text">Размер одного файла не может превышать 50MB!</p>-->

        <vue-dropzone
            ref="fff"
            id="dropzone"
            :useCustomSlot="true"
            :options="dropzoneOptions"
            @vdropzone-file-added="fileAdded"
            @vdropzone-removed-file="fileRemoved"
        >
          <div class="dropzone-container">
            <div class="file-selector">
              Загрузите файлы или перенесите их вручную
            </div>
          </div>
        </vue-dropzone>

        <v-divider class="my-5"></v-divider>

        <files v-if="item.files && item.files.length > 0"
          :title="'Прикрепленные файлы:'"
          :files="item.files"
          @remove="removeImage"
          :isRemovable="true"/>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="d-flex justify-space-between">
          <div>
            <v-btn
              class="mr-3"
              @click="$router.back()"
            >
              {{ $t("main.back") }}
            </v-btn>

            <v-btn
              v-if="item.status === 1 || item.status === 2"
              class="mr-3"
              color="primary"
              @click="save"
              :loading="loading"
              :disabled="loading"
            >
              Сохранить
            </v-btn>

            <v-btn
              v-if="item.status < 2"
              class="mr-3"
              color="blue"
              dark
              @click="accept"
              :loading="loading"
              :disabled="loading"
            >
              Принять
            </v-btn>

<!--            <v-btn
              v-if="hasWd && item.status !== 3 && (user.role === 'SuperAdmin' || user.role === 'ManagementCompany')"
              class="mr-3"
              color="blue"
              dark
              @click="transferToWd"
              :loading="loading"
              :disabled="loading"
            >
              Перевести в ГО
            </v-btn>-->

            <v-btn
              v-if="item.status !== 3 && (user.role === 'SuperAdmin' || user.role === 'WarrantyDepartment')"
              class="mr-3"
              color="blue"
              dark
              @click="transferToMc"
              :loading="loading"
              :disabled="loading"
            >
              Перевести в УК
            </v-btn>
          </div>

          <v-btn
            v-if="item.status === 7 || item.status < 3"
            color="red lighten-2"
            dark
            @click="dialog=true"
          >
            Завершить
          </v-btn>

          <v-dialog
            v-model="dialog"
            width="500"
            persistent
          >
            <v-card
              :loading="loading"
              :disabled="loading"
            >
              <v-card-title class="text-h5 grey lighten-2">
                Комментарий
              </v-card-title>

              <v-card-text>
                <v-textarea
                  v-model="commentForCitizen"
                  class="mt-4"
                  label="Информация по заявке"
                  outlined
                  clearable
                ></v-textarea>

                <v-file-input
                  v-model="fileForCitizen"
                  label="Файл"
                  outlined
                  clearable
                ></v-file-input>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn
                  color="primary"
                  text
                  @click="dialog = false"
                >
                  Закрыть
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="complete"
                >
                  Отправить
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getAllEmployee, getAllEmployeeByOsiId} from "@/services/employeeService";
import {getWorkCategories} from "@/services/workCategoriesService";
import {
  acceptTicket,
  completeTicket,
  getTicket,
  removeTicketFile,
  transferTicketToMc, transferTicketToWd,
  updateTicket, uploadTicketFiles
} from "@/services/ticketService";
import Files from "@/components/Files";
import {getAllEmployeeByMcId, getAllMcEmployee} from "@/services/mcEmployeeService";
import {getAllEmployeesByWdId, getAllWdEmployee} from "@/services/wdEmployeeService";
import {showUk} from "@/services/ukManagementService";
import {Role} from "@/helpers/role";
import Loader from '@/components/loader.vue';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  name: "TicketCreate",
  components: {Files, Loader, vueDropzone: vue2Dropzone},
  props: {
    id: String,
    osiId: String
  },
  data: () => ({
    breadcrumbs: [
      {
        text: 'Заявки',
        disabled: false,
        href: '/',
      },
      {
        text: 'Редактировать',
        disabled: true
      },
    ],
    dropzoneOptions: {
      url: 'https://httpbin.org/post',
      maxFilesize: 50,
      thumbnailWidth: 100,
      thumbnailHeight: 100,
      thumbnailMethod: 'crop',
      addRemoveLinks: true,
      uploadMultiple: true,
      dictCancelUpload: 'Отменить',
      dictRemoveFile: 'Удалить',
      resizeWidth: 100,
      resizeHeight: 100,
      acceptedFiles: [
        'image/png',
        'image/jpeg',
        'image/gif',
        'application/pdf',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/plain',
        'application/json',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ]
    },
    tab: null,
    applicant: {},
    categories: [],
    employees: [],
    mcEmployees: [],
    wdEmployees: [],
    item: {},
    houses: [],
    loading: false,
    commentForCitizen: null,
    fileForCitizen: null,
    menu: false,
    errors: [],
    dialog: false,
    completeConfirm: false,
    address: null,
    comment: null,
    hasWd: false,
    files: [],
    previousFiles: [],
    menu2: false,
    time: null,
    fileError: true,
    fileSize: 52428800,
    rules: [
      files => !files || !files.some(file => file.size > 52428800) || 'Размер одного файла не может превышать 50MB!'
    ],
  }),
  computed: {
    ...mapGetters({
      user: 'user',
      osiList: 'osiList',
      houseId: 'houseId',
      houseList: 'houseList'
    })
  },
  mounted() {
    this.fetchTicket()

    if(this.user.role === Role.uk){
      this.fetchMcEmployees(this.user.managementCompanyId)
      this.getUk()
    }

    if (this.user.role === Role.osi){
      this.fetchEmployees(this.user.osiId)
    }

    if (this.user.role === Role.wd){
      this.fetchWdEmployees(this.user.warrantyDepartmentId)
    }

    if (this.user.role === Role.superAdmin) {
      this.fetchAllWdEmployees()
      this.fetchAllMcEmployees()
      this.fetchAllEmployees()
    }

    if (this.user.role === Role.dispatcher) {
      if (this.user.osiId !== null) {
        this.fetchEmployees(this.user.osiId)
      } else {
        this.fetchMcEmployees(this.user.managementCompanyId)
      }
    }

    this.fetchCategories()
  },
  watch: {
    files(val) {
      this.previousFiles = val
    }
  },
  methods: {
    getUk() {
      this.loading = true
      showUk(this.user.managementCompanyId)
      .then(res => {
        this.hasWd = res.warrantyDepartment !== null
      })
      .finally(() => this.loading = false)
    },
    fetchTicket() {
      this.loading = true
      getTicket(this.id)
      .then(res => {
        this.applicant = res.applicant
        let currentDate = new Date()
        let executionDate = new Date(currentDate.getTime() - (currentDate.getTimezoneOffset() * 60000)).toJSON().slice(0, 10)
        let d = res.expectedExecutionDate.slice(0, 4) === '0001' ? executionDate : res.expectedExecutionDate.slice(0, 10)
        this.time = res.expectedExecutionDate.slice(11, 16)

        this.item = {
          id: res.id,
          rating: res.rating,
          categoryId: res.category ? res.category.id : null,
          employeeId: res.employee ? res.employee.id : null,
          mcEmployeeId: res.mcEmployee ? res.mcEmployee.id : null,
          wdEmployeeId: res.wdEmployee ? res.wdEmployee.id : null,
          apartment: res.address.apartment,
          houseId: res.address.house.id,
          commentForEmployee: res.commentForEmployee,
          description: res.description,
          expectedExecutionDate: d,
          histories: res.histories,
          status: res.status,
          files: res.files,
          requestNumber: res.requestNumber,
        }

        if (res.status === 1) {
          this.time = this.addHoursTime(new Date(), 6)
        }

        this.address = res.address.addressName
      })
      .finally(() => this.loading = false)
    },
    async fetchEmployees(id) {
      this.loading = true
      await getAllEmployeeByOsiId(id)
      .then(res => {
        this.employees = res.map(x => {
          return {
            id: x.id,
            name: x.fullName
          }
        })
      })
      .finally(() => this.loading = false)
    },
    async fetchMcEmployees(id){
      this.loading = true
      await getAllEmployeeByMcId(id)
      .then(res => {
        this.mcEmployees = res.map(x => {
          return {
            id: x.id,
            name: x.fullName
          }
        })
      })
      .finally(() => this.loading = false)
    },
    async fetchWdEmployees(id){
      this.loading = true
      await getAllEmployeesByWdId(id)
      .then(res => {
        this.wdEmployees = res.map(x => {
          return {
            id: x.id,
            name: x.fullName
          }
        })
      })
      .finally(() => this.loading = false)
    },
    async fetchAllEmployees() {
      this.loading = true
      await getAllEmployee()
      .then(res => {
        this.employees = res.map(x => {
          return {
            id: x.id,
            name: x.fullName
          }
        })
      })
      .finally(() => this.loading = false)
    },
    async fetchAllMcEmployees(){
      this.loading = true
      await getAllMcEmployee()
      .then(res => {
        this.mcEmployees = res.map(x => {
          return {
            id: x.id,
            name: x.fullName
          }
        })
      })
      .finally(() => this.loading = false)
    },
    async fetchAllWdEmployees(){
      this.loading = true
      await getAllWdEmployee()
      .then(res => {
        this.wdEmployees = res.map(x => {
          return {
            id: x.id,
            name: x.fullName
          }
        })
      })
      .finally(() => this.loading = false)
    },
    fetchCategories() {
      this.loading = true
      getWorkCategories()
      .then(res => {
        this.categories = res
      })
      .finally(() => this.loading = false)
    },
    save() {
      this.loading = true
      let params = {
        ticketId: this.item.id,
        categoryId: this.item.categoryId,
        employeeId: this.item.employeeId,
        mcEmployeeId: this.item.mcEmployeeId,
        wdEmployeeId: this.item.wdEmployeeId,
        expectedExecutionDate: this.item.expectedExecutionDate + ' ' + this.time,
        commentForEmployee: this.comment,
      }

      updateTicket(this.item.id, params)
      .then(res => {
        if (this.files.length > 0) {
          let dataForm = new FormData()
          for (let i = 0; i < this.files.length; i++) {
            dataForm.append('files', this.files[i])
          }

          dataForm.append('_method', 'POST');
          uploadTicketFiles(this.id, dataForm)
          .then(() => {
            this.commentForCitizen = null

            this.$store.dispatch("showAlert", {
              isVisible: true,
              msg: 'Заявка принята',
              color: "success",
              type: "success",
            });

            this.$router.push({path: '/', query: this.$route.query})
          })
        } else {
          this.$store.dispatch("showAlert", {
            isVisible: true,
            msg: 'Заявка обновлена',
            color: "success",
            type: "success",
          });

          this.$router.push({path: '/', query: this.$route.query})
        }
      })
      .catch(err => {
        this.errors = err.response.data.errors

        this.$store.dispatch("showAlert", {
          isVisible: true,
          msg: err.response.data.title,
          color: "error",
          type: "error",
        });
      })
      .finally(() => this.loading = false)
    },
    accept() {
      this.loading = true

      let exDate = this.item.expectedExecutionDate + ' ' + this.time
      let curDate = this.addHours(new Date(), 6)

      let isSameDate = new Date(exDate).getDate() >= new Date(curDate).getDate()
      let isSameHour = new Date(exDate).getHours() >= new Date(curDate).getHours()
      //let isSameMinutes = new Date(exDate).getMinutes() === new Date(curDate).getMinutes()

      let isCorrectExpectedExecutionDate = isSameDate && isSameHour

      if(!isCorrectExpectedExecutionDate){
        this.loading = false
        this.$store.dispatch("showAlert", {
          isVisible: true,
          msg: 'Дата исполнения некорректная, минимально +6 часов от текущей даты',
          color: "error",
          type: "error",
        });
        return
      }

      acceptTicket(this.id, {
        ticketId: this.id,
        categoryId: this.item.categoryId,
        employeeId: this.item.employeeId,
        mcEmployeeId: this.item.mcEmployeeId,
        expectedExecutionDate: exDate,
        commentForEmployee: this.comment
      })
      .then(res => {
        if (this.files.length > 0) {
          let dataForm = new FormData()
          for (let i = 0; i < this.files.length; i++) {
            dataForm.append('files', this.files[i])
          }

          dataForm.append('_method', 'POST');
          uploadTicketFiles(this.id, dataForm)
          .then(() => {
            this.commentForCitizen = null

            this.$store.dispatch("showAlert", {
              isVisible: true,
              msg: 'Заявка принята',
              color: "success",
              type: "success",
            });

            this.$router.push({path: '/', query: this.$route.query})
          })
        } else {
          this.commentForCitizen = null

          this.$store.dispatch("showAlert", {
            isVisible: true,
            msg: 'Заявка принята',
            color: "success",
            type: "success",
          });

          this.$router.push({path: '/', query: this.$route.query})
        }
      })
      .catch(err => {
        this.errors = err.response.data.errors
        this.$store.dispatch("showAlert", {
          isVisible: true,
          msg: err.response.data.title,
          color: "error",
          type: "error",
        });
      })
      .finally(() => {
        this.loading = false
        this.dialog = false
      })
    },
    addHours(date, hours) {
      date.setTime(date.getTime() + hours * 60 * 60 * 1000);
      return date;
    },
    addHoursTime(date, hours) {
      date.setTime(date.getTime() + hours * 60 * 60 * 1000);
      let h = date.getHours()
      if (h < 10) {
        h = '0' + h
      }
      let m = date.getMinutes()

      if (m < 10) {
        m = '0' + m
      }
      return h + ':' + m;
    },
    complete() {
      this.loading = true

      completeTicket(this.id, {
        commentForCitizen: this.commentForCitizen
      })
      .then(res => {
        if (this.fileForCitizen) {
          let dataForm = new FormData()
          dataForm.append('files', this.fileForCitizen)
          dataForm.append('_method', 'POST');
          uploadTicketFiles(this.id, dataForm)
          .then(() => {
            this.commentForCitizen = null

            this.$store.dispatch("showAlert", {
              isVisible: true,
              msg: 'Заявка завершена',
              color: "success",
              type: "success",
            });

            this.$router.push({path: '/', query: this.$route.query})
          })
        } else {
          this.commentForCitizen = null

          this.$store.dispatch("showAlert", {
            isVisible: true,
            msg: 'Заявка завершена',
            color: "success",
            type: "success",
          });

          this.$router.push({path: '/', query: this.$route.query})
        }
      })
      .catch(err => {
        this.$store.dispatch("showAlert", {
          isVisible: true,
          msg: err.response.data.title,
          color: "error",
          type: "error",
        });
      })
      .finally(() => {
        this.loading = false
        this.dialog = false
      })
    },
    transferToWd() {
      this.loading = true

      transferTicketToWd(this.id, {
        ticketComment: this.comment
      })
      .then(res => {
        this.$store.dispatch("showAlert", {
          isVisible: true,
          msg: 'Заявка переведена в ГО',
          color: "success",
          type: "success",
        });

        this.$router.push({path: '/', query: this.$route.query})
      })
      .catch(err => {
        this.$store.dispatch("showAlert", {
          isVisible: true,
          msg: err.response.data.title,
          color: "error",
          type: "error",
        });
      })
      .finally(() => {
        this.loading = false
        this.dialog = false
      })
    },
    transferToMc() {
      this.loading = true

      transferTicketToMc(this.id, {
        ticketComment: this.comment
      })
      .then(res => {
        this.$store.dispatch("showAlert", {
          isVisible: true,
          msg: 'Заявка переведена в УК',
          color: "success",
          type: "success",
        });
      
        this.$router.push({path: '/', query: this.$route.query})
      })
      .catch(err => {
        console.log(err)
        this.$store.dispatch("showAlert", {
          isVisible: true,
          msg: err.response.data.title,
          color: "error",
          type: "error",
        });
      })
      .finally(() => {
        this.loading = false
        this.dialog = false
      })
    },
    removeImage(id) {
      this.loading = true
      removeTicketFile(id)
      .then(res => {
        this.item.files = this.item.files.filter(x => x.id !== id)
      })
      .finally(() => this.loading = false)
    },
    dateFormat(date) {
      return new Date(date).toLocaleString([], { dateStyle: 'short', timeStyle: 'short' });
    },
    getStatusTitle(status) {
      if (status === 1) {
        return 'Новая'
      }else if (status === 2) {
        return 'Принята'
      }else if(status === 3) {
        return 'Завершена'
      }else if(status === 4) {
        return 'Отклонена'
      }else if(status === 5){
        return 'Исполнено'
      }else if(status === 6){
        return 'Отправлена в Гарантийный отдел'
      }else {
        return 'Просрочено'
      }
    },
    getStatusColor(status) {
      if (status === 1) {
        return 'blue'
      }else if (status === 2) {
        return 'amber'
      }else if(status === 3) {
        return 'gray'
      }else if(status === 4) {
        return 'black'
      }else if(status === 5){
        return 'green'
      }else if(status === 6){
        return 'teal'
      }else {
        return 'red'
      }
    },
    remove (index) {
      this.files.splice(index, 1)
    },
    fileAdded (file) {
      if (file.size <= this.fileSize) {
        this.files.push(file)
      }
    },
    fileRemoved(file) {
      let index = this.files.findIndex(x => x.name === file.name)
      if (index >= 0) {
        this.files.splice(index, 1)
      }
    }
  }
}
</script>